export let threeWindDirections = [
  {windDirection: 0, roughness: 0.5 },
  {windDirection: 120, roughness: 0.5 },
  {windDirection: 240, roughness: 0.5 },
];

export let sixWindDirections = [
  {windDirection: 0, roughness: 0.5 },
  {windDirection: 60, roughness: 0.5 },
  {windDirection: 120, roughness: 0.5 },
  {windDirection: 180, roughness: 0.5 },
  {windDirection: 240, roughness: 0.5 },
  {windDirection: 300, roughness: 0.5 },
];

export let eightWindDirections = [
  {windDirection: 0, roughness: 0.5 },
  {windDirection: 45, roughness: 0.5 },
  {windDirection: 90, roughness: 0.5 },
  {windDirection: 135, roughness: 0.5 },
  {windDirection: 180, roughness: 0.5 },
  {windDirection: 225, roughness: 0.5 },
  {windDirection: 270, roughness: 0.5 },
  {windDirection: 315, roughness: 0.5 }
];

export let sixteenWindDirections = [
  {windDirection: 0, roughness: 0.5 },
  {windDirection: 22.5, roughness: 0.5 },
  {windDirection: 45, roughness: 0.5 },
  {windDirection: 67.5, roughness: 0.5 },
  {windDirection: 90, roughness: 0.5 },
  {windDirection: 112.5, roughness: 0.5 },
  {windDirection: 135, roughness: 0.5 },
  {windDirection: 157.5, roughness: 0.5 },
  {windDirection: 180, roughness: 0.5 },
  {windDirection: 202.5, roughness: 0.5 },
  {windDirection: 225, roughness: 0.5 },
  {windDirection: 247.5, roughness: 0.5 },
  {windDirection: 270, roughness: 0.5 },
  {windDirection: 292.5, roughness: 0.5 },
  {windDirection: 315, roughness: 0.5 },
  {windDirection: 337.5, roughness: 0.5 },
];

export let eighteenWindDirections = [
  {windDirection: 0, roughness: 0.5},
  {windDirection: 20, roughness: 0.5},
  {windDirection: 40, roughness: 0.5},
  {windDirection: 60, roughness: 0.5},
  {windDirection: 80, roughness: 0.5},
  {windDirection: 100, roughness: 0.5},
  {windDirection: 120, roughness: 0.5},
  {windDirection: 140, roughness: 0.5},
  {windDirection: 160, roughness: 0.5},
  {windDirection: 180, roughness: 0.5},
  {windDirection: 200, roughness: 0.5},
  {windDirection: 220, roughness: 0.5},
  {windDirection: 240, roughness: 0.5},
  {windDirection: 260, roughness: 0.5},
  {windDirection: 280, roughness: 0.5},
  {windDirection: 300, roughness: 0.5},
  {windDirection: 320, roughness: 0.5},
  {windDirection: 340, roughness: 0.5},
];

export let thirtySixWindDirections = [
  {windDirection: 0, roughness: 0.5},
  {windDirection: 10, roughness: 0.5},
  {windDirection: 20, roughness: 0.5},
  {windDirection: 30, roughness: 0.5},
  {windDirection: 40, roughness: 0.5},
  {windDirection: 50, roughness: 0.5},
  {windDirection: 60, roughness: 0.5},
  {windDirection: 70, roughness: 0.5},
  {windDirection: 80, roughness: 0.5},
  {windDirection: 90, roughness: 0.5},
  {windDirection: 100, roughness: 0.5},
  {windDirection: 110, roughness: 0.5},
  {windDirection: 120, roughness: 0.5},
  {windDirection: 130, roughness: 0.5},
  {windDirection: 140, roughness: 0.5},
  {windDirection: 150, roughness: 0.5},
  {windDirection: 160, roughness: 0.5},
  {windDirection: 170, roughness: 0.5},
  {windDirection: 180, roughness: 0.5},
  {windDirection: 190, roughness: 0.5},
  {windDirection: 200, roughness: 0.5},
  {windDirection: 210, roughness: 0.5},
  {windDirection: 220, roughness: 0.5},
  {windDirection: 230, roughness: 0.5},
  {windDirection: 240, roughness: 0.5},
  {windDirection: 250, roughness: 0.5},
  {windDirection: 260, roughness: 0.5},
  {windDirection: 270, roughness: 0.5},
  {windDirection: 280, roughness: 0.5},
  {windDirection: 290, roughness: 0.5},
  {windDirection: 300, roughness: 0.5},
  {windDirection: 310, roughness: 0.5},
  {windDirection: 320, roughness: 0.5},
  {windDirection: 330, roughness: 0.5},
  {windDirection: 340, roughness: 0.5},
  {windDirection: 350, roughness: 0.5},
];

//We should really be getting the default seasons and times of day based on the criteria selected and the defaults defined in that criteria's recipe.  This "approximation"
//will hit it's limits soon...  to do that we probably need to recipe cards stored in the database instead of yaml files in the config repo
export let oneSeason = [
  {Name: 'Annual', Start: 1, End: 12}
];

export let twoSeasons = [
  {Name: 'Summer', Start: 6, End: 8},
  {Name: 'Winter', Start: 12, End: 2}
];

export let threeSeasons = [
  {Name: 'Summer', Start: 6, End: 8},
  {Name: 'Winter', Start: 12, End: 2},
  {Name: 'Annual', Start: 1, End: 12}
];

export let fourSeasons = [
  {Name: 'Spring', Start: 3, End: 5},
  {Name: 'Summer', Start: 6, End: 8},
  {Name: 'Autumn', Start: 9, End: 11},
  {Name: 'Winter', Start: 12, End: 2}
];

export let fiveSeasons = [
  {Name: 'Spring', Start: 3, End: 5},
  {Name: 'Summer', Start: 6, End: 8},
  {Name: 'Autumn', Start: 9, End: 11},
  {Name: 'Winter', Start: 12, End: 2},
  {Name: 'Annual', Start: 1, End: 12}
];

export let oneTimeOfDay = [
  {Name: 'Daily', Start: 6, End: 23}
];

export let twoTimesOfDay = [
  {Name: 'Morning', Start: 6, End: 12},
  {Name: 'Afternoon', Start: 12, End: 18}
];

export let threeTimesOfDay = [
  {Name: 'Morning', Start: 7, End: 11},
  {Name: 'Midday', Start: 11, End: 15},
  {Name: 'Afternoon', Start: 15, End: 18}
];

export let fourTimesOfDay = [
  {Name: 'Morning', Start: 7, End: 11},
  {Name: 'Midday', Start: 11, End: 15},
  {Name: 'Afternoon', Start: 15, End: 18},
  {Name: 'Night', Start: 18, End: 24}
];

export let fiveTimesOfDay = [
  {Name: 'Morning', Start: 6, End: 11},
  {Name: 'Midday', Start: 11, End: 15},
  {Name: 'Afternoon', Start: 15, End: 18},
  {Name: 'Evening', Start: 18, End: 21},
  {Name: 'Night', Start: 21, End: 24}
];