<template>
  <div class='screenshot-controls-container no-top-margin-all-descendants'>
    <h5>
      Screenshot
      <b-icon 
        icon='x'
        class="close-controls-button"
        @click="toggleScreenShotMode">
      </b-icon>
    </h5>
    <hr>
    <div>
      <label class='mt-2'>Aspect Ratio</label>
      <b-form-select v-model="aspectRatio" class="mb-2 aspect-ratio-select">
        <b-form-select-option :value="null">Please select an option</b-form-select-option>
        <b-form-select-option value="3:2">3:2</b-form-select-option>
        <b-form-select-option value="4:3">4:3</b-form-select-option>
        <b-form-select-option value="16:9">16:9</b-form-select-option>
        <b-form-select-option value="custom">custom</b-form-select-option>
      </b-form-select>
      <b-form-input
        class='aspect-ratio-input'
        :value="aspectX"
        @change="setAspectX"
        v-if="aspectRatio === 'custom'">
      </b-form-input>
      <span
        class='aspect-ratio-colon'
        v-if="aspectRatio === 'custom'">
        :
      </span>
      <b-form-input
        class='aspect-ratio-input'
        :value="aspectY"
        @change="setAspectY"
        v-if="aspectRatio === 'custom'">
      </b-form-input>
    </div>
    <div>
      <b-form-group 
        label='Resolution'
        label-class='resolution-radio-label'
        class='mb-0'>
        <b-form-radio 
          class='resolution-radio'
          name='resolution' 
          value='small'
          @change='setScreenShotSize("small")'>
          small
        </b-form-radio>
        <b-form-radio 
          class='resolution-radio'
          name='resolution' 
          value='medium'
          @change='setScreenShotSize("medium")'>
          medium
        </b-form-radio>
        <b-form-radio 
          class='resolution-radio'
          name='resolution' 
          value='large'
          @change='setScreenShotSize("large")'>
          large
        </b-form-radio>
      </b-form-group>
    </div>
    <div>
      <label class='mt-2'>Camera</label>
      <b-form-checkbox
        v-model="saveCamera"
        class="save-camera-checkbox"
        :disabled="onSavedCamera">
        save camera angle
      </b-form-checkbox>
    </div>
    <div>
      <b-button 
        variant='outline-primary bg-white border-white text-dark' 
        class='m-2' 
        ref="screenshotOne"
        :disabled="screenShotSize === '' || !aspectRatio || aspectX === 0 || aspectY === 0"
        @click='saveScreen()'>
        <b-icon icon='camera' class='mx-1'></b-icon>
        current display
      </b-button>
    </div>
    <div v-if='!isSplitView'>
      <b-button 
        variant='outline-primary bg-white border-white text-dark' 
        class='m-2' 
        ref="screenshotTwo"
        :disabled="screenShotSize === '' || !aspectRatio || aspectX === 0 || aspectY === 0"
        @click='screenshotAllSelectedLayers()'>
        <b-icon icon='download' class='mx-1'></b-icon>
        all layers
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenShotControls',
  props: {
    aspectX: {
      type: Number,
      required: true
    },
    aspectY: {
      type: Number,
      required: true
    },
    screenShotSize: {
      required: true
    },
    onSavedCamera: {
      required: true
    }
  },
  data() {
    return {
      aspectRatio: null,
      saveCamera: false
    };
  },
  methods: {
    setAspectX(x) {
      this.$emit('setAspectX', x);
    },
    setAspectY(y) {
      this.$emit('setAspectY', y);
    },
    setScreenShotSize(size) {
      this.$emit('setScreenShotSize', size);
    },
    saveScreen() {
      this.$emit('saveScreen', this.saveCamera);
    },
    screenshotAllSelectedLayers(){
      this.$emit('takeMultipleScreenshots', true);
    },
    toggleScreenShotMode() {
      this.$emit('toggleScreenShotMode');
    }
  },
  watch: {
    aspectRatio(newValue) {
      if(newValue && newValue !== 'custom') {
        let aspect = newValue.split(':');
        this.setAspectX(aspect[0]);
        this.setAspectY(aspect[1]);
      }
    },
    onSavedCamera(newValue) {
      if(newValue) this.saveCamera = false;
    }
  },
  computed: {
    isSplitView() {
      return !!this.$route.query?.secondary;
    }
  },
  mounted() {
    const shotOne = this.$refs.screenshotOne;
    const shotTwo = this.$refs.screenshotTwo;
    const innerWidth = shotOne.offsetWidth - (parseFloat(window.getComputedStyle(shotOne).paddingLeft)+parseFloat(window.getComputedStyle(shotOne).paddingRight)+
    (parseFloat(window.getComputedStyle(shotOne).borderRight)));
    shotTwo.style.width = `${innerWidth}px`;
  }
};
</script>

<style scoped>
.screenshot-controls-container {
  background: rgba(200,200,200,0.5);
  padding: 0.75rem;
  z-index: 1;
}

.aspect-ratio-input {
  display: inline;
  width: 3.375rem;
}

.aspect-ratio-colon {
  font-size: 1.5rem;
}

.custom-control-label::before {
  border: none;
}

.close-controls-button {
  float: right;
  cursor: pointer;
}

.aspect-ratio-select {
  display: block;
  width: 6.25rem;
}
</style>
<style>
.resolution-radio-label {
  font-weight: 600;
  padding-top: 0.5em !important;
}

.resolution-radio .custom-control-label::before {
  border: none;
}

.resolution-radio .custom-control-input {
  position: absolute;
}

.save-camera-checkbox .custom-control-input {
  position: absolute;
}
</style>
